<template>
    <div class="login-wrap">
        <div v-if="isShow" class="main-plr">
            <ul class="input-logo">
                <li class="flex align-items-center border-btm">
                    <!--                    <label>手机号：</label>-->
                    <van-field label="手机号：" v-model="form.username" type="tel" maxlength="11" clearable
                               clear-trigger="always"
                               placeholder="请输入手机号"/>
                </li>
                <li class="flex align-items-center border-btm">
                    <!--                    <label>验证码：</label>-->
                    <van-field label="验证码：" v-model="form.smscode" type="digit" clearable clear-trigger="always"
                               maxlength="6"
                               placeholder="请输入验证码">
                        <template #button>
                            <van-button class="btn_p27" size="small" color="#fe5e30" plain @click="getCode" :disabled="isDisabled">
                                {{smsText}}
                            </van-button>
                        </template>
                    </van-field>
                </li>
            </ul>
            <van-button class="saveBtn" type="primary" block :disabled="btnDisabled" :loading="saveloading"
                        loading-text="登录中..."
                        @click="save">
                登录
            </van-button>
        </div>
        <div v-else>
            <div class="error-con">
                <img src="../../assets/img/img@2x.png">
                <span>您（{{form.username}}）还不是我们的渠道商，无法登录系统！</span>
                <span>如果您想成为我们的渠道商可致电：</span>
                <strong class="color-fe5e30">400-00-18925</strong>
                <span>联系我们的渠道经理！</span>
            </div>
            <van-button class="saveBtn2 fontSize30" type="primary" block @click="isShow = true">
                确定
            </van-button>
        </div>
    </div>
</template>

<script>
    import {sms, login} from "../../assets/api/user";
    import {setToken} from "../../utils/cookies";

    export default {
        name: "Login",
        data() {
            return {
                isShow: true, // 登录失败结果显示
                form: {
                    type: 'loginSmscode',
                    username: null,
                    smscode: null
                },
                saveloading: false, // 登录按钮状态
                timerNum: 0, // 验证码倒数时间 s
                timer: null, // 发送验证码计时器
                isDisabled: false, // 验证发送状态
                smsText: '获取验证码' // 获取验证码按钮文本
            }
        },
        computed: {
            // 登录按钮状态
            btnDisabled: function () {
                return !(this.form.username && this.form.smscode);
            }
        },
        methods: {
            // 登录
            save() {
                this.saveloading = true
                login(this.form).then(res => {
                    setToken(res.data.type + ' ' + res.data.token)
                    this.$router.push({name: 'Index'})
                }).catch(() => {
                    this.resetLogin()
                })
            },
            // 重置登录
            resetLogin() {
                this.form.smscode = null
                // 当倒计时为0时，清空计时器
                this.clearCountDown()
                this.smsText = '获取验证码'
                this.isDisabled = false
                this.saveloading = false
            },
            // 获取短信验证码
            getCode() {
                this.isDisabled = true
                this.count_down()
                const param = {mobile: this.form.username, templateKey: 'SMS_PERSON_LOGIN'}
                sms(param).then(() => {
                    this.$toast.success('发送成功')
                }).catch(err => {
                    if (err.code === 202406) {
                        this.isShow = false
                    } else {
                        this.$toast(err.message)
                    }
                    this.clearCountDown() // 如果失败清除计数器，可重新发送验证码
                    this.isDisabled = false
                })
            },
            // 60S倒计时
            count_down() {
                this.timerNum = 60
                this.timer = setInterval(() => {
                    if (this.timerNum > 0) {
                        this.timerNum--
                        this.smsText = this.timerNum + 's后重发'
                    } else {
                        this.clearCountDown() // 当倒计时为0时，清空计时器
                        this.smsText = '重发验证码'
                        this.isDisabled = false
                    }
                }, 1000)
            },
            // 清除计数器
            clearCountDown() {
                this.timerNum = 0
                clearInterval(this.timer)
            },
        }
    }
</script>

<style lang="less">
    .login-wrap .van-field__label {
        color: @color-191b21;
    }
</style>

<style scoped lang="less">
    .login-wrap {
        padding-top: 16px;
        box-sizing: border-box;
        height: 100%;

        .main-plr {
            height: 100%;
            background: #fff;
        }

        li {
            height: 92px;

            .van-field {
                .align-items-center;
            }
        }


        .error-con {
            span, strong, img {
                display: block;
                text-align: center;
            }

            img {
                margin: 57px auto 82px;
                width: 382px;
                height: 273px;
            }

            span {
                font-size: @font-size1;
                margin-bottom: 32px;
            }

            strong {
                font-size: @font-size8;
                margin-bottom: 32px;
            }
        }

        .input-logo .van-button {
            padding: 0 8px;
        }

        .saveBtn {
            margin-top: 69px;
            font-size: @font-size3;
        }

        .saveBtn2 {
            font-size: @font-size3;
            width: 686px;
            margin: 72px auto 0;
        }
    }
</style>